import React, { useEffect } from 'react'

const getPgaTld = () => {
  if (window) {
    return window.location.origin.includes('.sandboxpga.org') || window.location.origin.includes('.pga.local') ? 'sandboxpga.org' : 'pga.org'
  }
}

export default () => {
  useEffect(() => {
    window.location.assign(`https://account.${getPgaTld()}/login`)
  }, [])

  return null
}
